import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import List from "../components/list"
import Layout from "../components/layout"
import SEO from "../components/seo"

let items = []

const WordFamiles = ({ data }) => (
  <Layout>
    <SEO title="All Word Familes" />

    <h2 className="subtitle is-3">Word Familes</h2>

    {Object.entries(data.allStrapiWordfamily.edges).forEach(
      ([key, document]) => {
        if (
          document.node.url === "none" ||
          document.node.url === undefined ||
          document.node.url === null
        ) {
          items[key] = {
            id: document.node.id,
            link: `/${document.node.id}`,
            title: document.node.Title,
            color: document.node.Color,
          }
        } else {
          items[key] = {
            id: document.node.id,
            link: `/${document.node.url}`,
            title: document.node.Title,
            color: document.node.Color,
          }
        }
      }
    )}
    <List items={items} />
  </Layout>
)
WordFamiles.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WordFamiles

export const pageQuery = graphql`
  query WordFamilesQuery {
    allStrapiWordfamily(sort: { fields: Title }) {
      edges {
        node {
          Title
          id
          Color
          url
        }
      }
    }
  }
`
